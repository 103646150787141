import { forwardRef } from "react";

function Root(props, ref) {
  return (
    <svg
    width="26"
    height="26"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <circle cx="24" cy="24" r="24" fill="white" />
      <path
        d="M36.9979 35H32.0287V27.2045C32.0287 25.3455 31.9956 22.9525 29.4443 22.9525C26.8562 22.9525 26.4601 24.9778 26.4601 27.0691V34.9994H21.4911V18.9686H26.2614V21.1594H26.3283C27.2996 19.4958 29.1122 18.4985 31.035 18.57C36.0713 18.57 37 21.8885 37 26.2058L36.9979 35Z"
        fill="#12a9b2"
      />
      <path
        d="M15.8843 16.7773C15.884 16.7773 15.8838 16.7773 15.8837 16.7773C14.3017 16.7773 13 15.4734 13 13.8887C13 12.304 14.3017 11 15.8837 11C17.4655 11 18.767 12.3037 18.7673 13.8881C18.7673 13.8883 18.7673 13.8884 18.7673 13.8887C18.7673 15.4731 17.4659 16.7772 15.8843 16.7773Z"
        fill="#12a9b2"
      />
      <path d="M18.3688 35H13.3945V18.9686H18.3688V35Z" fill="#12a9b2" />
    </svg>
  );
}
const Linkedin = forwardRef(Root);
export default Linkedin;
