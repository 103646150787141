import { forwardRef } from "react";

function Root(props, ref) {
  return (
    <svg
    width="25"
    height="25"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <circle cx="24" cy="24" r="24" fill="#fff" />
      <path
        d="M36.7239 17.4726C36.7435 17.7547 36.7434 18.0368 36.7434 18.3215C36.7434 26.9958 30.1393 37 18.0635 37V36.9948C14.4962 37 11.003 35.9783 8 34.0518C8.51871 34.1142 9.04002 34.1454 9.56262 34.1467C12.5189 34.1493 15.3906 33.1574 17.7164 31.3311C14.907 31.2778 12.4435 29.4462 11.5829 26.7722C12.567 26.962 13.581 26.923 14.5469 26.6592C11.4841 26.0404 9.28052 23.3496 9.28052 20.2246C9.28052 20.196 9.28052 20.1687 9.28052 20.1414C10.1931 20.6496 11.215 20.9317 12.2602 20.9629C9.37542 19.0351 8.48621 15.1978 10.2282 12.1976C13.5615 16.2988 18.4795 18.792 23.7588 19.0559C23.2297 16.7759 23.9525 14.3866 25.6582 12.7838C28.3024 10.2984 32.4612 10.4258 34.9468 13.0685C36.4171 12.7786 37.8264 12.2392 39.116 11.4748C38.6259 12.9944 37.6002 14.2852 36.2299 15.1055C37.5313 14.9521 38.8027 14.6037 40 14.072C39.1186 15.3928 38.0084 16.5432 36.7239 17.4726Z"
        fill="#12a9b2"
      />
    </svg>
  );
}
const Twitter = forwardRef(Root);
export default Twitter;
